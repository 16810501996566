var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            staticClass: "p-0",
            class: {
              "container-layout": _vm.$screen.width >= 992,
              "container-mobile": _vm.$screen.width < 992,
            },
          },
          [
            _c(
              "h1",
              {
                class: [
                  `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                  { "is-pwa": _vm.$isPwa() },
                ],
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.FormMSG(10, "Contacts")) +
                    "\n\t\t\t"
                ),
              ]
            ),
            _c(
              "b-overlay",
              {
                attrs: { show: _vm.isLoading, opacity: "0.75", rounded: "lg" },
                on: {
                  "update:show": function ($event) {
                    _vm.isLoading = $event
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "tabs-project",
                    class: `${_vm.$screen.width <= 576 ? "my-project" : ""}`,
                  },
                  [
                    _c(
                      "b-tabs",
                      {
                        staticStyle: { "flex-wrap": "inherit" },
                        attrs: {
                          card: "",
                          "active-nav-item-class": "font-weight-bold",
                          "active-tab-class": "font-weight-bold",
                        },
                      },
                      [
                        _c(
                          "b-tab",
                          {
                            attrs: {
                              title: _vm.FormMSG(2150, "Per department"),
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                class: {
                                  "container-layout": _vm.$screen.width > 567,
                                  "container-mobile": _vm.$screen.width <= 567,
                                },
                              },
                              [
                                _c(
                                  "b-row",
                                  {
                                    staticClass:
                                      "form content-contact-mobile pt-0",
                                    class: `${
                                      _vm.$screen.width <= 567 ? "" : "px-4"
                                    }`,
                                  },
                                  [
                                    _vm.isDeterProject === true
                                      ? _c(
                                          "b-col",
                                          {
                                            attrs: {
                                              sm: "12",
                                              md: "6",
                                              lg: "6",
                                              xl: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label:
                                                    _vm.$screen.width > 567
                                                      ? _vm.FormMSG(
                                                          817,
                                                          "Team session"
                                                        )
                                                      : "",
                                                  "label-cols":
                                                    _vm.$screen.width > 567
                                                      ? 4
                                                      : "",
                                                },
                                              },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    label: "text",
                                                    options: _vm.sessionOptions,
                                                    placeholder: _vm.FormMSG(
                                                      851,
                                                      "Select team session"
                                                    ),
                                                    reduce: (option) =>
                                                      option.value,
                                                  },
                                                  on: {
                                                    input:
                                                      _vm.handleChangeSessionOptionSelected,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.sessionOptionSelected,
                                                    callback: function ($$v) {
                                                      _vm.sessionOptionSelected =
                                                        $$v
                                                    },
                                                    expression:
                                                      "sessionOptionSelected",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: {
                                          sm: "12",
                                          md: "6",
                                          lg: "6",
                                          xl: "6",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm.FormMSG(
                                                  54,
                                                  "Type of search"
                                                ),
                                              },
                                              model: {
                                                value: _vm.filter,
                                                callback: function ($$v) {
                                                  _vm.filter = $$v
                                                },
                                                expression: "filter",
                                              },
                                            }),
                                            _c(
                                              "b-input-group-append",
                                              { staticClass: "cursor-pointer" },
                                              [
                                                _c(
                                                  "b-input-group-text",
                                                  { staticClass: "btn-search" },
                                                  [
                                                    _vm.filter.length === 0
                                                      ? _c(
                                                          _vm.getLucideIcon(
                                                            "Search"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color: "#FFFFFF",
                                                              size: 16,
                                                              "stroke-width": 2.5,
                                                            },
                                                          }
                                                        )
                                                      : _c(
                                                          _vm.getLucideIcon(
                                                            "X"
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color: "#FFFFFF",
                                                              size: 16,
                                                              "stroke-width": 2.5,
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.clearFilter,
                                                            },
                                                          }
                                                        ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c("ContactPerDeps", {
                                  attrs: { contacts: _vm.ContactsFilteredMap },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "b-tab",
                          {
                            attrs: { title: _vm.FormMSG(2151, "Alphabetical") },
                            on: { click: _vm.handleAlphabeticalTab },
                          },
                          [
                            _vm.$screen.width <= 567
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "container-mobile animated fadeIn",
                                      staticStyle: { top: "95px" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form content-contact-mobile",
                                        },
                                        [
                                          _c(
                                            "form",
                                            [
                                              _c(
                                                "b-row",
                                                [
                                                  _vm.isDeterProject === true
                                                    ? _c(
                                                        "b-col",
                                                        {
                                                          attrs: {
                                                            sm: "12",
                                                            md: "5",
                                                            lg: "5",
                                                            xl: "5",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.$screen
                                                                    .width > 567
                                                                    ? _vm.FormMSG(
                                                                        817,
                                                                        "Team session"
                                                                      )
                                                                    : "",
                                                                "label-cols":
                                                                  _vm.$screen
                                                                    .width > 567
                                                                    ? 4
                                                                    : "",
                                                              },
                                                            },
                                                            [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  label: "text",
                                                                  options:
                                                                    _vm.sessionOptions,
                                                                  placeholder:
                                                                    _vm.FormMSG(
                                                                      851,
                                                                      "Select team session"
                                                                    ),
                                                                  reduce: (
                                                                    option
                                                                  ) =>
                                                                    option.value,
                                                                },
                                                                on: {
                                                                  input:
                                                                    _vm.handleChangeSessionOptionSelected,
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.sessionOptionSelected,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.sessionOptionSelected =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "sessionOptionSelected",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        sm: "12",
                                                        md: "7",
                                                        lg: "7",
                                                        xl: "7",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-input-group",
                                                        [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              type: "text",
                                                              placeholder:
                                                                _vm.FormMSG(
                                                                  54,
                                                                  "Type of search"
                                                                ),
                                                            },
                                                            model: {
                                                              value: _vm.filter,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.filter =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "filter",
                                                            },
                                                          }),
                                                          _c(
                                                            "b-input-group-append",
                                                            {
                                                              staticClass:
                                                                "cursor-pointer",
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group-text",
                                                                {
                                                                  staticClass:
                                                                    "btn-search",
                                                                },
                                                                [
                                                                  _vm.filter
                                                                    .length ===
                                                                  0
                                                                    ? _c(
                                                                        _vm.getLucideIcon(
                                                                          "Search"
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#FFFFFF",
                                                                              size: 16,
                                                                              "stroke-width": 2.5,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        _vm.getLucideIcon(
                                                                          "X"
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#FFFFFF",
                                                                              size: 16,
                                                                              "stroke-width": 2.5,
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              _vm.clearFilter,
                                                                          },
                                                                        }
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-row",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "16px",
                                                  },
                                                },
                                                [
                                                  _c("b-col", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "title-list",
                                                      },
                                                      [
                                                        _c("h2", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.FormMSG(
                                                                25,
                                                                "Name"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _vm.ContactsFilteredMap.length ===
                                              0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              74,
                                                              "No result"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                _vm.ContactsFilteredMap,
                                                function (contact, index) {
                                                  return _c(
                                                    "div",
                                                    { key: index },
                                                    [
                                                      index > 0
                                                        ? _c("div", {
                                                            staticClass:
                                                              "divider-line mt-3 mb-3",
                                                          })
                                                        : _vm._e(),
                                                      _c(
                                                        "b-row",
                                                        {
                                                          class: {
                                                            "mt-3": index === 0,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              staticClass:
                                                                "text-center",
                                                              attrs: {
                                                                cols: "2",
                                                              },
                                                            },
                                                            [
                                                              contact.picture
                                                                ? _c("div", [
                                                                    _c("img", {
                                                                      staticClass:
                                                                        "img-list-contact p-0 border-0",
                                                                      attrs: {
                                                                        src: _vm.pathPicture(
                                                                          contact.picture
                                                                        ),
                                                                      },
                                                                    }),
                                                                  ])
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "img-list-contact",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "user",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#C4C4C4",
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              staticClass:
                                                                "pr-0",
                                                              attrs: {
                                                                cols: "7",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "name-contact mb-0",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      contact.name
                                                                    ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        contact.firstName
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("p", [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      contact.departmentName
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                                _c("span", {
                                                                  staticClass:
                                                                    "divider-dot",
                                                                }),
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      contact.customFunctionName
                                                                        ? contact.customFunctionName
                                                                        : contact.functionName
                                                                    ) +
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              staticClass:
                                                                "pr-0 pl-0",
                                                              attrs: {
                                                                cols: "3",
                                                              },
                                                            },
                                                            [
                                                              _c("div", {}, [
                                                                _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _c(
                                                                      "b-dropdown",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "border-right":
                                                                              "0px !important",
                                                                            "padding-top":
                                                                              "0px !important",
                                                                          },
                                                                        attrs: {
                                                                          "no-caret":
                                                                            "",
                                                                          dropleft:
                                                                            "",
                                                                          offset:
                                                                            "20",
                                                                          variant:
                                                                            "none",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "button-content",
                                                                                fn: function () {
                                                                                  return [
                                                                                    _c(
                                                                                      "b-button",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "border-radius":
                                                                                              "5px",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            variant:
                                                                                              "light",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "more-vertical"
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                                proxy: true,
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      },
                                                                      [
                                                                        !contact.showMyPhoneInCrew &&
                                                                        !contact.showMyEmailInCrew &&
                                                                        index ==
                                                                          0
                                                                          ? _c(
                                                                              "b-dropdown-item-button",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    disabled:
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      _vm.FormMSG(
                                                                                        25621,
                                                                                        "No option available"
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        contact.showMyPhoneInCrew
                                                                          ? _c(
                                                                              "b-dropdown-item-button",
                                                                              {
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.callPhone(
                                                                                        contact
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "Phone",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        fill: "#06263E",
                                                                                        size: 16,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  "   " +
                                                                                    _vm._s(
                                                                                      _vm.FormMSG(
                                                                                        3561,
                                                                                        "Call"
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        contact.showMyPhoneInCrew
                                                                          ? _c(
                                                                              "b-dropdown-item-button",
                                                                              {
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.handleSendSms(
                                                                                        contact
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "MessageCircle",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        fill: "#06263E",
                                                                                        size: 16,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  "  \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      _vm.FormMSG(
                                                                                        77887678,
                                                                                        "Send Sms"
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        contact.showMyEmailInCrew
                                                                          ? _c(
                                                                              "b-dropdown-item-button",
                                                                              {
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.sendEmail(
                                                                                        contact
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "Mail",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        fill: "#06263E",
                                                                                        color:
                                                                                          "#E2E6EB",
                                                                                        size: 16,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      _vm.FormMSG(
                                                                                        3562,
                                                                                        "Send an email"
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                        contact.showMyPhoneInCrew
                                                                          ? _c(
                                                                              "b-dropdown-item-button",
                                                                              {
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.showVcardModal(
                                                                                        contact
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "Copy",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        fill: "#06263E",
                                                                                        color:
                                                                                          "#E2E6EB",
                                                                                        size: 16,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " \n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                                    _vm._s(
                                                                                      _vm.FormMSG(
                                                                                        3563,
                                                                                        "Copy contact"
                                                                                      )
                                                                                    ) +
                                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "form container-layout" },
                                  [
                                    _vm.Contacts
                                      ? _c(
                                          "b-card",
                                          [
                                            _vm.$screen.width >= 992
                                              ? _c(
                                                  "b-row",
                                                  { staticClass: "pb-3" },
                                                  [
                                                    _vm.isDeterProject === true
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              sm: "12",
                                                              md: "5",
                                                              lg: "5",
                                                              xl: "5",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-group",
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    _vm.$screen
                                                                      .width >
                                                                    567
                                                                      ? _vm.FormMSG(
                                                                          817,
                                                                          "Team session"
                                                                        )
                                                                      : "",
                                                                  "label-cols":
                                                                    _vm.$screen
                                                                      .width >
                                                                    567
                                                                      ? 4
                                                                      : "",
                                                                },
                                                              },
                                                              [
                                                                _c("v-select", {
                                                                  attrs: {
                                                                    label:
                                                                      "text",
                                                                    options:
                                                                      _vm.sessionOptions,
                                                                    placeholder:
                                                                      _vm.FormMSG(
                                                                        851,
                                                                        "Select team session"
                                                                      ),
                                                                    reduce: (
                                                                      option
                                                                    ) =>
                                                                      option.value,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      _vm.handleChangeSessionOptionSelected,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.sessionOptionSelected,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.sessionOptionSelected =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "sessionOptionSelected",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          sm: "12",
                                                          md: "7",
                                                          lg: "7",
                                                          xl: "7",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-input-group",
                                                          [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  _vm.FormMSG(
                                                                    54,
                                                                    "Type of search"
                                                                  ),
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.filter,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.filter =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "filter",
                                                              },
                                                            }),
                                                            _c(
                                                              "b-input-group-append",
                                                              {
                                                                staticClass:
                                                                  "cursor-pointer",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-input-group-text",
                                                                  {
                                                                    staticClass:
                                                                      "btn-search",
                                                                  },
                                                                  [
                                                                    _vm.filter
                                                                      .length ===
                                                                    0
                                                                      ? _c(
                                                                          _vm.getLucideIcon(
                                                                            "Search"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#FFFFFF",
                                                                                size: 16,
                                                                                "stroke-width": 2.5,
                                                                              },
                                                                          }
                                                                        )
                                                                      : _c(
                                                                          _vm.getLucideIcon(
                                                                            "X"
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "#FFFFFF",
                                                                                size: 16,
                                                                                "stroke-width": 2.5,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                _vm.clearFilter,
                                                                            },
                                                                          }
                                                                        ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-col",
                                                  {
                                                    attrs: {
                                                      cols: "12",
                                                      xl: "12",
                                                    },
                                                  },
                                                  [
                                                    _vm.$screen.width < 992
                                                      ? _c("CardListBuilder", {
                                                          attrs: {
                                                            items:
                                                              _vm.ContactsFilteredMap,
                                                            fields:
                                                              _vm.contactMobileFields,
                                                          },
                                                          on: {
                                                            "row-clicked":
                                                              _vm.rowClicked,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "actions",
                                                                fn: function (
                                                                  slotProps
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "btn bg-transparent border-0",
                                                                        attrs: {
                                                                          variant:
                                                                            "success",
                                                                          size: "sm",
                                                                          title:
                                                                            _vm.FormMSG(
                                                                              51,
                                                                              "Send email"
                                                                            ),
                                                                          disabled:
                                                                            !slotProps
                                                                              .item
                                                                              .showMyEmailInCrew,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.sendEmail(
                                                                                slotProps.item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            _vm
                                                                              .ICONS
                                                                              .MAIL
                                                                              .name
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .MAIL
                                                                                    .color,
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "btn bg-transparent border-0",
                                                                        attrs: {
                                                                          variant:
                                                                            "success",
                                                                          disabled:
                                                                            slotProps
                                                                              .item
                                                                              .phone
                                                                              .length <
                                                                              1 &&
                                                                            !slotProps
                                                                              .item
                                                                              .showMyPhoneInCrew,
                                                                          size: "sm",
                                                                          title:
                                                                            _vm.FormMSG(
                                                                              52,
                                                                              "Call by phone"
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.callPhone(
                                                                                slotProps.item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            _vm
                                                                              .ICONS
                                                                              .PHONE
                                                                              .name
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .PHONE
                                                                                    .color,
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "btn bg-transparent border-0",
                                                                        attrs: {
                                                                          variant:
                                                                            "success",
                                                                          disabled:
                                                                            slotProps
                                                                              .item
                                                                              .picture
                                                                              .length <
                                                                            1,
                                                                          size: "sm",
                                                                          title:
                                                                            _vm.FormMSG(
                                                                              53,
                                                                              "View picture"
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.showPicture(
                                                                                slotProps
                                                                                  .item
                                                                                  .picture
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            _vm
                                                                              .ICONS
                                                                              .PICTURE
                                                                              .name
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .PICTURE
                                                                                    .color,
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "btn bg-transparent border-0",
                                                                        attrs: {
                                                                          variant:
                                                                            "success",
                                                                          size: "sm",
                                                                          disabled:
                                                                            slotProps
                                                                              .item
                                                                              .phone
                                                                              .length <
                                                                              1 &&
                                                                            !slotProps
                                                                              .item
                                                                              .showMyPhoneInCrew,
                                                                          title:
                                                                            _vm.FormMSG(
                                                                              58765,
                                                                              "View vCard"
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.showVcardModal(
                                                                                slotProps.item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            _vm
                                                                              .ICONS
                                                                              .COPY
                                                                              .name
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .COPY
                                                                                    .color,
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            1765512561
                                                          ),
                                                        })
                                                      : _vm._e(),
                                                    _vm.$screen.width >= 992
                                                      ? _c("b-table", {
                                                          attrs: {
                                                            hover: _vm.hover,
                                                            responsive: "sm",
                                                            items: _vm.Contacts,
                                                            fields:
                                                              _vm.contactFields,
                                                            "current-page":
                                                              _vm.currentPage,
                                                            filter: _vm.filter,
                                                            "per-page":
                                                              _vm.perPage,
                                                            "sticky-header":
                                                              "700px",
                                                            "head-variant":
                                                              _vm.hv,
                                                            "sort-by":
                                                              _vm.sortBy,
                                                            bordered: "",
                                                            small: "",
                                                          },
                                                          on: {
                                                            "row-clicked":
                                                              _vm.rowClicked,
                                                            "update:sortBy":
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.sortBy =
                                                                  $event
                                                              },
                                                            "update:sort-by":
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.sortBy =
                                                                  $event
                                                              },
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "cell(functionName)",
                                                                fn: function (
                                                                  data
                                                                ) {
                                                                  return [
                                                                    _vm._v(
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                        _vm._s(
                                                                          data
                                                                            .item
                                                                            .customFunctionName
                                                                            ? data
                                                                                .item
                                                                                .customFunctionName
                                                                            : data
                                                                                .item
                                                                                .functionName
                                                                        ) +
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                              {
                                                                key: "cell(pict)",
                                                                fn: function (
                                                                  data
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "mr-1 btn bg-transparent border-0",
                                                                        attrs: {
                                                                          variant:
                                                                            "success",
                                                                          size: "sm",
                                                                          title:
                                                                            _vm.FormMSG(
                                                                              51,
                                                                              "Send email"
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.sendEmail(
                                                                                data.item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            _vm
                                                                              .ICONS
                                                                              .MAIL
                                                                              .name
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .MAIL
                                                                                    .color,
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    data.item
                                                                      .showMyPhoneInCrew
                                                                      ? _c(
                                                                          "b-button",
                                                                          {
                                                                            staticClass:
                                                                              "mr-1 btn bg-transparent border-0",
                                                                            attrs:
                                                                              {
                                                                                variant:
                                                                                  "success",
                                                                                disabled:
                                                                                  data
                                                                                    .item
                                                                                    .phone
                                                                                    .length <
                                                                                  1,
                                                                                size: "sm",
                                                                                title:
                                                                                  _vm.FormMSG(
                                                                                    52,
                                                                                    "Call by phone"
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.callPhone(
                                                                                    data.item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              _vm.getLucideIcon(
                                                                                _vm
                                                                                  .ICONS
                                                                                  .PHONE
                                                                                  .name
                                                                              ),
                                                                              {
                                                                                tag: "component",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      _vm
                                                                                        .ICONS
                                                                                        .PHONE
                                                                                        .color,
                                                                                    size: 20,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "btn bg-transparent border-0",
                                                                        attrs: {
                                                                          variant:
                                                                            "success",
                                                                          disabled:
                                                                            data
                                                                              .item
                                                                              .picture
                                                                              .length <
                                                                            1,
                                                                          size: "sm",
                                                                          title:
                                                                            _vm.FormMSG(
                                                                              53,
                                                                              "View picture"
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.showPicture(
                                                                                data
                                                                                  .item
                                                                                  .picture
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            _vm
                                                                              .ICONS
                                                                              .PICTURE
                                                                              .name
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .PICTURE
                                                                                    .color,
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "btn bg-transparent border-0",
                                                                        attrs: {
                                                                          variant:
                                                                            "success",
                                                                          size: "sm",
                                                                          title:
                                                                            _vm.FormMSG(
                                                                              58765,
                                                                              "View vCard"
                                                                            ),
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.showVcardModal(
                                                                                data.item
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          _vm.getLucideIcon(
                                                                            _vm
                                                                              .ICONS
                                                                              .COPY
                                                                              .name
                                                                          ),
                                                                          {
                                                                            tag: "component",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .COPY
                                                                                    .color,
                                                                                size: 20,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            3318580973
                                                          ),
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-modal",
                                              {
                                                staticClass: "modal-success",
                                                attrs: {
                                                  "header-class":
                                                    "header-class-modal-doc-package",
                                                  title: _vm.FormMSG(
                                                    20,
                                                    "Picture"
                                                  ),
                                                  "ok-variant": "success",
                                                  "ok-only": "",
                                                  "modal-class":
                                                    "mui-animation",
                                                  fade: false,
                                                },
                                                on: {
                                                  ok: function ($event) {
                                                    _vm.showContactPicture = false
                                                  },
                                                },
                                                model: {
                                                  value: _vm.showContactPicture,
                                                  callback: function ($$v) {
                                                    _vm.showContactPicture = $$v
                                                  },
                                                  expression:
                                                    "showContactPicture",
                                                },
                                              },
                                              _vm._l(
                                                _vm.contactImagePath,
                                                function (oneImage, i) {
                                                  return _c(
                                                    "b-card",
                                                    { key: i },
                                                    [
                                                      _c(
                                                        "b-container",
                                                        {
                                                          staticClass:
                                                            "p-4 bg-dark",
                                                          attrs: { fluid: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-row",
                                                            [
                                                              _c(
                                                                "b-col",
                                                                [
                                                                  _c("b-img", {
                                                                    attrs: {
                                                                      fluid: "",
                                                                      src: oneImage,
                                                                    },
                                                                  }),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                          ]
                        ),
                        _c("VcfContactModal", {
                          attrs: { contact: _vm.currContactVCard },
                          on: {
                            closed: function ($event) {
                              _vm.currContactVCard = null
                            },
                          },
                          model: {
                            value: _vm.isVcardModalOpen,
                            callback: function ($$v) {
                              _vm.isVcardModalOpen = $$v
                            },
                            expression: "isVcardModalOpen",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }