var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      staticClass: "modal-success",
      attrs: {
        "dialog-class": "",
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(207896, "Contact info"),
        "ok-variant": "success",
        "ok-only": "",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: { ok: _vm.handleVCardModalClose, show: _vm.generateVCard },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ ok }) {
            return [
              _c(
                "b-button",
                {
                  attrs: { variant: "light" },
                  on: {
                    click: function ($event) {
                      return ok()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.FormMSG(97987, "Close")))]
              ),
              _c(
                "b-button",
                {
                  staticClass: "push_r_10",
                  attrs: {
                    size: "small",
                    disabled: _vm.isContactCardDlownloadLoading,
                  },
                  on: { click: _vm.handleDownloadvCard },
                },
                [
                  _vm.isContactCardDlownloadLoading
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _vm._e(),
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.FormMSG(3224234, "Download")) +
                      "\n\t\t"
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isModalOpen,
        callback: function ($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen",
      },
    },
    [
      _vm.$has(_vm.contact)
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "full clearfix contact-name-title",
                staticStyle: { "text-align": "center" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "text-center",
                    staticStyle: { "font-size": "20px" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.contact.name) +
                        " " +
                        _vm._s(_vm.contact.firstName) +
                        " "
                    ),
                  ]
                ),
              ]
            ),
            _c("div", { staticClass: "full clearfix content_to_center" }, [
              _c("img", {
                staticClass: "qrcode-img",
                attrs: { src: _vm.vCardQrCodeSrc },
              }),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }